/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import {useEffect} from 'react';
import {navigate} from 'gatsby';

const RedirectToHome = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};

export default RedirectToHome;
